
import _copy from '../../copy/documentation/documentation';
import Products from '../../templates/ProductsTemplate/ProductsTemplate';
import React from 'react'

const DeveloperPage = () => {
  return (
    <Products _copy={_copy}/>
  )
}

export default DeveloperPage
